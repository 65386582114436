export const events = {
  AckEvent: '.ack-event',
  NewContactBroadcast: '.App\\Events\\NewContactBroadcast',
  RevokeMessageBroadcast: '.App\\Events\\RevokeMessageBroadcast',
  UpdateMessage: '.App\\Events\\UpdateMessage',
  NewMessage: '.new-message',
  MessageReaction: '.message-reaction',
  UnlistContact: '.App\\Events\\UnlistContact',
  FishingAttendant: '.App\\Events\\FishingAttendant',
  FishingNewMessage: '.App\\Events\\FishingNewMessage',
  UnfishAttendant: '.App\\Events\\UnfishAttendant',
  DeletedContact: '.App\\Events\\DeletedContact',
  broadcast: {
    FollowNewMessage: '.App\\Events\\Broadcast\\MessageBroadcastToFollow',
    ContactList: '.contact-list@broadcast',
  },
  CompanyIsDisabled: '.App\\Events\\CompanyIsDisabled',
  MarkConversationAs: '.App\\Events\\MarkConversationAs',
  v2: {
    AckEvent: '.App\\Events\\v2\\AckEvent',
    NewContact: '.App\\Events\\v2\\NewContact',
    ContactEdited: '.App\\Events\\v2\\ContactEdited',
    DeletedContact: '.App\\Events\\v2\\DeletedContact',
    MessageEdit: '.App\\Events\\v2\\MessageEdit',
    MessageEvent: '.App\\Events\\v2\\MessageEvent',
    UnlistContact: '.App\\Events\\v2\\UnlistContact',
    RevokeMessage: '.App\\Events\\v2\\RevokeMessage',
    GroupAckEvent: '.App\\Events\\v2\\GroupAckEvent',
    ReactionMessage: '.App\\Events\\v2\\ReactionMessage',
    FinishChatState: '.App\\Events\\v2\\FinishChatState',
    UnfishAttendant: '.App\\Events\\v2\\UnfishAttendant',
    FishingAttendant: '.App\\Events\\v2\\FishingAttendant',
    FishingNewMessage: '.App\\Events\\v2\\FishingNewMessage',
    GroupMessageEvent: '.App\\Events\\v2\\GroupMessageEvent',
    FailedToSendMessage: '.App\\Events\\v2\\FailedToSendMessage',
    ChatStateReactivated: '.App\\Events\\v2\\ChatStateReactivated',
    ChatStateTakenFromBot: '.App\\Events\\v2\\ChatStateTakenFromBot',
    ChatStateInitialized: '.App\\Events\\v2\\ChatStateInitialized',
    TransactionalMessage: '.App\\Events\\v2\\TransactionalMessage',
    SetExternalIdOnMessage: '.App\\Events\\v2\\SetExternalIdOnMessage',
    LinkConversationToAttendant:
      '.App\\Events\\v2\\LinkConversationToAttendant',
    LinkConversationToDepartment:
      '.App\\Events\\v2\\LinkConversationToDepartment',
    NotifyThatTheConversationHasBeenTransferred:
      '.App\\Events\\v2\\NotifyThatTheConversationHasBeenTransferred',
  },
}
