<script setup lang="ts">
const authStore = useAuthStore()

onBeforeMount(() => {
  const userId = document
    .querySelector('meta[name="ipc:user:id"]')
    ?.getAttribute('content')
  const companyId = document
    .querySelector('meta[name="ipc:company:id"]')
    ?.getAttribute('content')

  nextTick(() => {
    if (!userId && !companyId) {
      return
    }

    window.UserSocket = window.Echo.private(`user.${userId}`)
    window.ChatSocket = window.Echo.private(`v2-chat.${companyId}.${userId}`)
    window.GroupSocket = window.Echo.private(`v2-group.${companyId}`)
    window.CompanySocket = window.Echo.join(`v2-company.${companyId}`)

    if (authStore.isObserver) {
      window.ObserverSocket = window.Echo.private(`v2-observer.${companyId}`)
    }
  })
})
</script>
<template>
  <slot />
</template>
