export const refresher = () => {
  const typedMessage = document.getElementById('textMessage')
  if (
    typedMessage === null ||
    _.isEmpty((typedMessage as HTMLInputElement)?.value)
  ) {
    console.info('Refreshing...')
    window.location.reload()

    return true
  }

  return false
}
