<script setup lang="ts">
import { usePage } from '@inertiajs/vue3'

const authProps = computed(() => usePage().props.auth)

const agencyStore = useAgencyStore()

onMounted(() => {
  const { agency } = authProps.value

  agencyStore.setAgency({ agency })
})
</script>
<template>
  <slot />
</template>
