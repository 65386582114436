<script setup lang="ts">
import Queue from 'queue'

const stateStore = useStateStore()
const contactStore = useContactStore()
const conversationsStore = useConversationsStore()

const queue = new Queue({ autostart: true, concurrency: 1 })

onMounted(async () => {
  queue.push(
    async () => await stateStore.syncManifest({ status: [ 'active', 'finished' ] }),
    async () => await contactStore.syncManifestV2(),
    async () => await conversationsStore.makeConversations({ status: 'active' }),
    (cb) => {
      conversationsStore.$patch({ loadingConversations: false })
      if (cb) cb()
    },
    async () => await conversationsStore.makeConversations({ status: 'finished' })
  )
})
</script>
<template>
  <slot />
</template>
