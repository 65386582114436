import { createI18n } from 'vue-i18n'

export const i18n = createI18n({
  legacy: false,
  locale: 'pt-br',
  fallbackLocale: 'pt-br',
  messages: {
    'pt-br': {
      finished: 'finalizado | finalizados',
      active: 'ativo | ativos',
      contact: 'contato',
      attendant: 'atendente',
      admin: 'administrador',
      minute: '{n} minuto | {n} minutos',
      day: '{n} dia | {n} dias',
      connected: 'conectado',
      disconnected: 'desconectado',
      authenticated: 'autenticado',
      init: 'iniciando',
      NotFoundTableResult: 'nenhum resultado encontrado',
      contact_view: {
        all: 'todos',
        himself: 'os próprios contatos',
      },
      tableCountResults: {
        showing: 'Exibindo',
        results: '{total} resultado | {total} resultados',
        linked:
          '@:tableCountResults.showing {count} de @:tableCountResults.results',
      },
      servicePhases: {
        attendant: 'Em atendimento',
        bot_await_response: 'O bot está aguardando resposta',
        attendant_assignment: 'Aguardando atendimento',
      },
    },
  },
})
