<script setup lang="ts">
import { BuildingOfficeIcon } from '@heroicons/vue/24/outline'
const authStore = useAuthStore()

const companiesOptions = computed(() =>
  authStore.companies.filter((el) => el.id !== authStore.company.id),
)

function changeCompany(companyId: number) {
  window.location.href = route('dashboard.changeCompany', {
    _query: { company: companyId },
  })
}
</script>
<template>
  <div
    v-if="authStore.companies.length > 1"
    class="tw-daisy-dropdown tw-daisy-dropdown-end"
  >
    <div tabindex="0" role="button" class="tw-daisy-btn tw-m-1 tw-no-animation">
      <BuildingOfficeIcon class="tw-size-6 sm:tw-size-5" />
      <span class="tw-hidden sm:tw-block">{{ authStore.company.name }}</span>
    </div>
    <ul
      tabindex="0"
      class="tw-daisy-menu tw-daisy-dropdown-content tw-bg-base-100 tw-rounded-box tw-z-[1] tw-mt-3 tw-w-52 tw-p-2 tw-shadow"
    >
      <li class="sm:tw-hidden">
        <input
          type="radio"
          name="company"
          class="tw-daisy-btn tw-daisy-btn-sm tw-daisy-btn-block tw-daisy-btn-ghost tw-no-animation tw-justify-start"
          :aria-label="authStore.company.name"
          :value="authStore.company.name"
          checked
        />
      </li>
      <li v-for="(company, index) in companiesOptions" :key="index">
        <input
          type="radio"
          name="company"
          class="tw-daisy-btn tw-daisy-btn-sm tw-daisy-btn-block tw-daisy-btn-ghost tw-no-animation tw-justify-start"
          :aria-label="company.name"
          :value="company.name"
          @change="changeCompany(company.id)"
        />
      </li>
    </ul>
  </div>
</template>
