import { App } from 'vue'

export default {
  install: (app: App<any>) => {
    const asset = (file: string) => {
      return window.Vapor.asset(file)
    }

    app.provide('asset', asset)
    app.config.globalProperties.$asset = asset
  },
}
