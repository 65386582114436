import './bootstrap'
import '../css/globals.css'
import '../css/chat-globals.css'
import '../css/app.css'

import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css'
import '@quasar/extras/animate/fadeIn.css'
import '@quasar/extras/animate/fadeOut.css'
import 'quasar/dist/quasar.css'

import 'vue-toast-notification/dist/theme-sugar.css'

import { createApp, h, defineAsyncComponent, DefineComponent } from 'vue'
import { createPinia } from 'pinia'
import { Link, createInertiaApp, Head, router } from '@inertiajs/vue3'
import { i18n } from './i18n'
import { ZiggyVue } from '../../vendor/tightenco/ziggy'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

// Plugins
import FormatTime from './Plugins/FormatTime'
import IsOnlyOneEmoji from './Plugins/EmojiPattern'
import FormatDate from './Plugins/FormatDate'
import ScrollToBottom from './Plugins/ScrollToBottom'
import DaysOfWeek from './Plugins/DaysOfWeek'
import VaporAsset from './Plugins/VaporAsset'
import VueChatScroll from 'vue3-chat-scroll'

// Quasar
import { Quasar, Notify, Dialog, Loading, LoadingBar, Meta } from 'quasar'
import quasarLang from 'quasar/lang/pt-BR'
import quasarIconSet from 'quasar/icon-set/material-symbols-outlined'

// Layouts
import DashboardLayout from './Layouts/Dashboard/DashboardLayout.vue'
import AuthLayout from './Layouts/Auth/AuthLayout.vue'

// Providers
import SocketProviderV2 from './Providers/SocketProviderV2.vue'
import AuthWrapper from './Providers/AuthWrapper.vue'
import AgencyWrapper from './Providers/AgencyWrapper.vue'
import Channels from './Providers/Channels.vue'
import LoadConversations from './Providers/LoadConversations.vue'

// Components
const Modal = defineAsyncComponent(() => import('@/Components/Modal.vue'))

const SendForm = defineAsyncComponent(
  () => import('@/Pages/Dashboard/Setting/Bot/Partials/SendForm.vue'),
)
const SendMessage = defineAsyncComponent(
  () => import('@/Pages/Dashboard/Setting/Bot/Partials/SendMessage.vue'),
)
const TransferAttendant = defineAsyncComponent(
  () => import('@/Pages/Dashboard/Setting/Bot/Partials/TransferAttendant.vue'),
)
const TransferDepartment = defineAsyncComponent(
  () => import('@/Pages/Dashboard/Setting/Bot/Partials/TransferDepartment.vue'),
)

const appName = window.document.getElementsByTagName('title')[0]?.innerText

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

Notify.registerType('error', {
  icon: 'o_error',
  progress: true,
  color: 'red',
  actions: [{ icon: 'o_close', color: 'white' }],
})

Notify.registerType('success', {
  icon: 'o_done',
  progress: true,
  color: 'positive',
  actions: [{ icon: 'o_close', color: 'white' }],
})

Notify.setDefaults({
  position: 'top-right',
})

let timeout: NodeJS.Timeout

router.on('start', () => {
  timeout = setTimeout(() => LoadingBar.start(), 250)
})

router.on('progress', (event) => {
  if (LoadingBar.isActive && event?.detail.progress?.percentage) {
    LoadingBar.increment((event.detail.progress.percentage / 100) * 0.9)
  }
})

router.on('finish', (event) => {
  clearTimeout(timeout)
  if (!LoadingBar.isActive) {
    //
  } else if (event.detail.visit.completed) {
    LoadingBar.stop()
  } else if (event.detail.visit.interrupted) {
    LoadingBar.increment(0)
  } else if (event.detail.visit.cancelled) {
    LoadingBar.stop()
  }
})

createInertiaApp({
  progress: false,
  title: (title) => `${title ? `${title} - ` : ''}${appName}`,
  resolve: (name) => {
    const page = resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob<DefineComponent>('./Pages/**/*.vue'),
    )

    if (name.startsWith('Dashboard/')) {
      page.then((module) => {
        module.default.layout = module.default.layout || [
          AuthWrapper,
          Channels,
          SocketProviderV2,
          LoadConversations,
          DashboardLayout,
        ]
      })
    } else if (name.startsWith('Chat/')) {
      page.then((module) => {
        module.default.layout = module.default.layout || [
          AuthWrapper,
          Channels,
          SocketProviderV2,
          LoadConversations,
        ]
      })
    } else if (name.startsWith('Auth/')) {
      page.then((module) => {
        module.default.layout = module.default.layout || [
          AgencyWrapper,
          AuthLayout,
        ]
      })
    }

    return page
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({
      render: () => h(App, props),
    })
    app
      .use(Quasar, {
        plugins: {
          Meta,
          Notify,
          Dialog,
          Loading,
          LoadingBar,
        },
        config: {
          dark: false,
          brand: {
            primary: '#007FFF',
          },
          loadingBar: {
            color: 'primary',
            skipHijack: true,
          },
        },
        lang: quasarLang,
        iconSet: quasarIconSet,
      })
      .use(plugin)
      .use(pinia)
      .use(i18n)
      .use(VaporAsset)
      .use(FormatTime)
      .use(FormatDate)
      .use(DaysOfWeek)
      .use(IsOnlyOneEmoji)
      .use(ScrollToBottom)
      .use(VueChatScroll)
      .use(ZiggyVue)
      .component('Modal', Modal)
      .component('HeadPage', Head)
      .component('TransferDepartment', TransferDepartment)
      .component('TransferAttendant', TransferAttendant)
      .component('SendForm', SendForm)
      .component('SendMessage', SendMessage)
      .component('Link', Link)

    app.mount(el)

    return app
  },
})
